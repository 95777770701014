import './polyfills';
import App from './api/app';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';

window.React = React;
window.ReactDOM = ReactDOM;

window.$ = window.jQuery = $;
require('jquery-ui-dist/jquery-ui');
require('jquery-ui-dist/jquery-ui.css');
require('jquery-mask-plugin');
require('jquery-lazyload');
require('./assets/js/jquery.maskMoney.min.js');
require('inputmask/dist/jquery.inputmask.js');

require('wheelzoom');

window.md5 = require('md5');

//require('@fortawesome/fontawesome-free/css/all.css');
//require('@fortawesome/fontawesome-free/css/fontawesome.min.css');

// Bootstrap 4
//require('bootstrap/scss/bootstrap.scss');

require('./assets/sass/index.scss');

// Bootstrap 3 
// require('./assets/sass/bootstrap_variables.scss');
// require('bootstrap-sass');
require('bootstrap-sass/assets/javascripts/bootstrap.js');

require('bootstrap-select');
require('bootstrap-select/dist/css/bootstrap-select.min.css');

require('bootstrap-switch');
require('bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css');

require('bootstrap-toggle');
require('bootstrap-toggle/css/bootstrap-toggle.min.css');

require('clockpicker/dist/bootstrap-clockpicker.js');
require('clockpicker/dist/bootstrap-clockpicker.css');

window.Highcharts = require('highcharts');
require('highcharts/highcharts-more')(window.Highcharts);
require('highcharts/modules/solid-gauge')(window.Highcharts);

window.PDFObject = require('pdfobject');

require('detect.js');

require('anychart/dist/anychart.min.js');
require('anychart/dist/anygantt.min.js');
require('anychart/dist/anychart-ui.min.js');
/*
require('anychart/dist/js/anychart-core.min.js');
require('anychart/dist/js/anychart-gantt.min.js');
require('anychart/dist/js/anychart-ui.min.js');
*/
// NEXT
//require('./components/context_menu.js');
//require('./components/select-multi-column.js');
//require('./components/selectpicker_bp.js');

require('./components/layout.css');
require('./components/table_bp_padrao.css');
require('./components/vertical_timeline.css');
require('./components/select-multi-column.css');

require('jstree');
require('jstree/dist/themes/default/style.min.css');

require('devextreme/dist/css/dx.common.css');
require('devexpress-diagram/dist/dx-diagram.min.css');
require('devexpress-gantt/dist/dx-gantt.min.css');
require('./themes/generated/theme.base.css');
//require('./themes/generated/theme.additional.css');
require('jszip');
require('./dx.custom.config.js');

require('tinymce');
require('tinymce/icons/default');
require('tinymce/plugins/autoresize');
require('tinymce/plugins/media');
require('tinymce/plugins/advlist');
require('tinymce/plugins/autolink');
require('tinymce/plugins/lists');
require('tinymce/plugins/link');
require('tinymce/plugins/image');
require('tinymce/plugins/imagetools');
require('tinymce/plugins/charmap');
require('tinymce/plugins/print');
require('tinymce/plugins/preview');
require('tinymce/plugins/hr');
require('tinymce/plugins/anchor');
require('tinymce/plugins/pagebreak');
require('tinymce/plugins/searchreplace');
require('tinymce/plugins/visualblocks');
require('tinymce/plugins/visualchars');
require('tinymce/plugins/code');
require('tinymce/plugins/insertdatetime');
require('tinymce/plugins/nonbreaking');
require('tinymce/plugins/table');
require('tinymce/plugins/directionality');
require('tinymce/plugins/template');
require('tinymce/plugins/paste');
require('tinymce/plugins/textpattern');
require('tinymce/plugins/fullscreen');
require('tinymce/plugins/help');
require('tinymce/plugins/wordcount');
require('tinymce/skins/ui/oxide/skin.min.css');
require('tinymce/skins/ui/oxide/content.min.css');
require('tinymce/themes/silver/');
require('./assets/tinymce/pt_BR.js');

window.CodeMirror = require('codemirror');
require('codemirror/lib/codemirror.css');
require('codemirror/mode/javascript/javascript.js');
require('codemirror/mode/sql/sql.js');
require('codemirror/mode/css/css.js');
require('codemirror/addon/display/autorefresh.js');

window.CircularJSON = require('circular-json');

window.moment = require('moment');
require('moment/locale/pt-br');
window.moment.locale('pt-br');

let carregarScriptJS = (script) => {
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.src = script + '?v=' + App.version;
    $("head").append(s);
}

$(window).on('load', function() {
    new window.App();
    carregarScriptJS('assets/js/sys/libs/jquery.ba-throttle-debounce.min.js');
    carregarScriptJS('assets/js/sys/libs/context_menu.js');
    carregarScriptJS('assets/js/sys/libs/select-multi-column.js');
    carregarScriptJS('assets/js/sys/selectpicker_bp.js');
    carregarScriptJS('assets/js/sys/table_bp_padrao.js');
    carregarScriptJS('assets/js/sys/fix.js');
});

require('./views/public.js');
/*
require('jszip/dist/jszip.min');

require('devextreme-aspnet-data/js/dx.aspnet.data.js');
require('devexpress-diagram/dist/dx-diagram.min.js');
window.DevExtreme = require('devextreme/dist/js/dx.all.js');

ReactDOM.render(
  <React.StrictMode>
    <AppPadrao />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// eslint-disable-next-line no-unused-vars
const WS_URI = window.WS_URI = process.env.NODE_ENV !== "production" ? "http://localhost:3001/webservice/index.php/" : "./webservice/index.php/";
// eslint-disable-next-line no-unused-vars
const WS_ROOT = window.WS_ROOT = process.env.NODE_ENV !== "production" ? "http://localhost:3001/" : "";
// eslint-disable-next-line no-unused-vars
const VIEW_URI = window.VIEW_URI = process.env.NODE_ENV !== "production" ? "http://localhost:3001/webservice/especificos/view/" : "./webservice/especificos/view/";
// eslint-disable-next-line no-unused-vars
const WS_URI_PE_PHONEGAP = window.WS_URI_PE_PHONEGAP = "./";
// eslint-disable-next-line no-unused-vars
const LANGS_URI = window.LANGS_URI = "./langs/";
// eslint-disable-next-line no-unused-vars
const SYS_ADMIN = window.SYS_ADMIN = false;
// eslint-disable-next-line no-unused-vars
const AUTH_TOKEN = window.AUTH_TOKEN = false;
// eslint-disable-next-line no-unused-vars
const XHR_WITH_CREDENTIALS = window.XHR_WITH_CREDENTIALS = false;
// eslint-disable-next-line no-unused-vars
const LOGOUT = window.LOGOUT = false;