import WS from '../../api/ws';
import React from 'react';
import $ from 'jquery';

export default class CardAvaliacaoFormulario extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = this.propsToObjState();

        this.formularioRef = React.createRef();
        this.renderFormulario = this.renderFormulario.bind(this);
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: this.props.detalhes ? this.props.detalhes.formulario_avaliacao_id : null,
            readOnly: this.props.readOnly ?? false
        };
    }

    componentDidMount() {
        this.renderFormulario();
    }
    

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes) {
            this.setState(this.propsToObjState());
        }
    }

    render() {
        return (
            <div ref={this.formularioRef}></div>
        );
    }

    renderFormulario() {
        let main = this.props.main ?? {};
        
        main.html = $(
            '<div class="aba_personalizado">'+
                '<div class="titulo_formulario">Campos Personalizados</div>' +
                '<div class="form-editor">' +
                        
                    '<div class="form-grupos panel-body" style="padding: 0px;">' +
                    '</div>' +

                    '<div class="form-grupo" template-grupo template-ref="" style="display: none;">' +
                        '<div>' +
                            '<h3 class="text-primary lbl_grupo_nome"></h3>' +
                        '</div>' +
                    '</div>' +

                    '<div class="form-grupo-campo" template-campo template-ref="" style="display: none;">' +
                        '<div class="div_campo form-group">' +
                            '<span class="lbl_obrigatorio text-danger">* </span>' +
                            '<label class="lbl_nome">{Nome}</label>' +
                            '<div class="div_input">' +
                            '</div>' +
                        '</div>' +
                    '</div>' +

                '</div>' +
            '</div>'
        );

        main.html_id = 'html_id';
        main.aba_personalizado = main.html;
        main.titulo_formulario = main.html.find('.titulo_formulario');
        main.form_editor = main.html.find('.form-editor');
        main.form_grupos = main.html.find('.form-grupos');
        main.template_grupo = main.html.find('[template-grupo]');
        main.template_campo = main.html.find('[template-campo]');
        main.lbl_nome = main.html.find('.lbl_nome');

        WS.get("formulario_chamado_avaliacao/get_formulario/",
            {chamado_id: this.state.detalhes.id},
            (response) => {
                if(response){
                    main.campos_personalizados = response;
                    main.titulo_formulario.html(response.nome);

                    if(main.campos_personalizados.registros){
                        $.each(main.campos_personalizados.registros, function(index, value) {
                            if(index === "campo_anexo"){
                                if(value){
                                    $.each(value, function(index2, value2) {
                                        if(value2){
                                            var arr = value2.split(";");
                                            var id_revisao = parseInt(arr[0]);
                                            var nome = arr[1];
                                            $.each(main.campos_personalizados.grupos, function(index3, value3) {
                                                $.each(value3.elementos, function(index4, value4) {
                                                    if(index2 === value4.id_elemento){
                                                        value4.valor = id_revisao;
                                                        value4.documento_revisao = {};
                                                        value4.documento_revisao.nome_arquivo = nome;
                                                        value4.documento_revisao.mime_type = window.extensao_to_mimetype(nome.substring(nome.lastIndexOf(".") + 1, nome.length));
                                                    }
                                                });
                                            });
                                        }
                                    });
                                }
                            }else if(index === "selecao_documento"){
                                $.each(value, function(index2, value2) {
                                    $.each(main.campos_personalizados.grupos, function(index3, value3) {
                                        $.each(value3.elementos, function(index4, value4) {
                                            if(index2 === value4.id_elemento){
                                                value4.valor = value2;
                                            }
                                        });
                                    }); 
                                });
                            }else if(index === "campo_tabela"){
                                $.each(value, function(index2, value2) {
                                    if(typeof value2 === "string"){
                                        /**
                                         * Este ajuste foi feito para adequar o JSON Salvo com erros na estrutura para JSON válido, pois antes estava salvando JSON's inválidos
                                         * e isto quebrava a visualização, então ele preve o erro e corrige.
                                         * (\"{2})(\w.+?)\"{2} -> Separo em 2 grupos de match
                                         * O primeiro grupo deve pegar tudo que tiver exatamente 2 aspas + Texto e finalize com exatamente 2 aspas
                                         * EX: ""NOME DE USUARIO""
                                         * E substituo por aspas + valor do 2 grupo que contém o texto  + fecha aspas
                                         * Ex: "NOME DE USUARIO"
                                         * **/
                                        // eslint-disable-next-line no-useless-escape
                                        try{  value2 = JSON.parse(value2.replace(/(\"{2})(\w.+?)\"{2}/gm, '"$2"').replace(/(\r\n|\n|\r)/gm," ")); }
                                        // eslint-disable-next-line no-useless-escape
                                        catch(e){ console.warn(value2,value2.replace(/(\"{2})(\w.+?)\"{2}/gm, '"$2"').replace(/(\r\n|\n|\r)/gm," ")) }
                                    }
                                    
                                    if(value2){
                                        $.each(main.campos_personalizados.grupos, function(index3, value3) {
                                            $.each(value3.elementos, function(index4, value4) {
                                                if(index2 === value4.id_elemento){
                                                    value4.tamanho_render = value2.tamanho_render;
                                                    value4.valor = value2.valor;
                                                    if(typeof value4.valor !== "undefined"){
                                                        $.each(value4.valor, function(index5, value5) {
                                                            if(value5.revisao != null){
                                                                var arr = value5.revisao.split(";");
                                                                var id_revisao = parseInt(arr[0]);
                                                                var nome = arr[1];
                                                                value5.valor = id_revisao;
                                                                value5.documento_revisao = {};
                                                                value5.documento_revisao.nome_arquivo = nome;
                                                                value5.documento_revisao.mime_type = window.extensao_to_mimetype(nome.substring(nome.lastIndexOf(".") + 1, nome.length));
                                                            }
                                                        })
                                                    }
                                                }
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    }

                    window.require(['./assets/view/formulario/util'], (formulario_util) => {
                        var util = new formulario_util();
                        util.render_previsao({
                            div_formulario: main,
                            template_campo: main.template_campo,
                            template_grupo: main.template_grupo,
                            obj_formulario: main.campos_personalizados,
                            disabled_all: this.state.readOnly,
                            liberar_campos: !this.state.readOnly
                        });

                        if(response.registros){
                            $.each(response.registros, function(idx, obj) {
                                if(idx === "campo_geral"){
                                    Object.keys(response.registros.campo_geral).map(function(objectKey, index) {
                                        main.aba_personalizado.find('[id_elemento=' + objectKey + ']').val(response.registros.campo_geral[objectKey]);
                                        main.aba_personalizado.find('[id_elemento=' + objectKey + ']').change();
                                        main.aba_personalizado.find('[id_elemento=' + objectKey + ']').blur();
                                        return objectKey;
                                    });
                                }else if(idx === "campo_decimal"){
                                    Object.keys(response.registros.campo_decimal).map(function(objectKey, index) {
                                        if(response.registros.campo_decimal[objectKey]){
                                            main.aba_personalizado.find('[id_elemento=' + objectKey + ']').val((response.registros.campo_decimal[objectKey]).replace(".",","));
                                            main.aba_personalizado.find('[id_elemento=' + objectKey + ']').change();
                                            main.aba_personalizado.find('[id_elemento=' + objectKey + ']').blur();
                                        }
                                        return objectKey;
                                    });
                                }else if(idx === "lista_opcoes"){
                                    Object.keys(response.registros.lista_opcoes).map(function(objectKey, index) {
                                        var opt = main.aba_personalizado.find('[id_elemento=' + objectKey + ']').find('option');
                                        var opt_bd = (response.registros.lista_opcoes[objectKey]);
                                        if (opt_bd != null) opt_bd = opt_bd.split(";");
                                        var indice = 0;
                                        
                                        if(opt && opt_bd){
                                            $.each(opt, function(index, value) {
                                                $.each(opt_bd, function(index2, value2) {
                                                    if(value.value === value2){
                                                        value.selected = true;
                                                        $(value).change();
                                                        $(value).blur();
                                                        indice = $(value).index();
                                                        $(value).parent().parent().find("[data-original-index='" + indice + "']").addClass("selected");
                                                    }
                                                }); 
                                            }); 
                                        }

                                        return objectKey;
                                    });
                                }else if(idx === "radio"){
                                    Object.keys(response.registros.radio).map(function(objectKey, index) {
                                        var elemento = main.aba_personalizado.find('[id_elemento=' + objectKey + ']');
                                        elemento.parent().find("input[value='" + response.registros.radio[objectKey] + "']").prop('checked', true);
                                        elemento.parent().find("input[value='" + response.registros.radio[objectKey] + "']").change();
                                        return objectKey;
                                    });
                                }else if(idx === "checkbox"){
                                    Object.keys(response.registros.checkbox).map(function(objectKey, index) {
                                        var opt_bd = (response.registros.checkbox[objectKey]);
                                        if (opt_bd != null) opt_bd = opt_bd.split(";");
                                        var elemento = main.aba_personalizado.find('[id_elemento=' + objectKey + ']');
                                        if(opt_bd){
                                            $.each(opt_bd, function(index, value) {
                                                elemento.parent().find("input[value='" + value + "']").prop('checked', true);
                                            });
                                        }
                                        return objectKey;
                                    });
                                }else if(idx === "'caixa_verificacao'"){
                                    $.each(response.registros.caixa_verificacao, function(index, value) {
                                        if(value === "S"){
                                            main.aba_personalizado.find('[id_elemento=' + index + ']').prop('checked', true);
                                        }
                                    });
                                }else if(idx === "editor_texto"){
                                    setTimeout(function(){
                                        Object.keys(response.registros.editor_texto).map(function(objectKey, index) {
                                            window.tinyMCE.get(main.aba_personalizado.find('[id_elemento=' + objectKey + ']').attr("id")).setContent(response.registros.editor_texto[objectKey]);
                                            return objectKey;
                                        });
                                    }, 500);
                                }else if(idx === "campo_integracao"){
                                    Object.keys(response.registros.campo_integracao).map(function(objectKey, index) {
                                        main.aba_personalizado.find('[id_elemento=' + objectKey + ']').find('.dx-dropdownbox').dxDropDownBox('instance').option('value', response.registros.campo_integracao[objectKey]);
                                    });
                                }
                            });
                        }
                        
                        $(this.formularioRef.current).html(
                            main.form_editor[0]
                        );
                    });
                }
                
            }

        );
    }
}