import SelecaoPadrao from './SelecaoPadrao';
import { chamadoMotivoColunas, chamadoMotivoList } from '../datasource/ChamadoMotivo';

export default class SelecaoChamadoMotivo extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            dataSource: chamadoMotivoList(this.props.chamadoId, this.props.grupoMotivoId, this.props.motivoVinculadoID),
            readOnly: this.props.readOnly ?? null,
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chamadoId !== this.props.chamadoId
            || prevProps.grupoMotivoId !== this.props.grupoMotivoId
            || prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && item.nome;
}

SelecaoChamadoMotivo.defaultProps = {
    chamadoId: null,
    grupoMotivoId: null,
    aoAlterar: null,
    displayExpr: displayExpr,
    columns: chamadoMotivoColunas,
};