import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export let chamadoSolicitanteList = (chamadoId, entidadeTipo, entidadeId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'usuario_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "chamado/listar_solicitantes_para_selecao/", 
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    entidade_tipo: entidadeTipo,
                    entidade_id: entidadeId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar_solicitantes_para_selecao/", 
                data: {
                    dx_grid: true,
                    usuario_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});

export const colunaSituacaoNome = {
    dataField: "nome",
    caption: "Nome"
};

export let chamadoResponsavelList = (chamadoId, entidadeTipo, entidadeId, motivoId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'usuario_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "chamado/listar_responsaveis_para_selecao/", 
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    entidade_tipo: entidadeTipo,
                    entidade_id: entidadeId,
                    motivo_id: motivoId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar_responsaveis_para_selecao/", 
                data: {
                    dx_grid: true,
                    usuario_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});

export let chamadoSituacaoList = (chamadoId) => new DataSource({
    key: 'id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "chamado/listar_situacao_para_selecao/", 
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar_situacao_para_selecao/", 
                data: {
                    dx_grid: true,
                    situacao_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});

export let reclassificar = (parametros, callbackAoSalvar, callbackAoFalhar) => {
    
    const aoSalvar = (response) => {
        if (response.chamado.rascunho === true) {
            window.alert_info('Rascunho salvo com sucesso');
        }
        else {
            window.alert_saved('Reclassificação salva com sucesso');
        }
        if (callbackAoSalvar) callbackAoSalvar(response);
    };
    
    const aoFalhar = (response) => {
        window.alert_fail('Falhou');
        if (callbackAoFalhar) callbackAoFalhar(response);
    };

    WS.post({
        route: "chamado/salvar",
        data: parametros,
        func_response: aoSalvar,
        func_fail: aoFalhar,
    });
}