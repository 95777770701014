import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaUsuarioNome = {
    dataField: "nome",
    caption: "Nome"
};

export const colunaUsuarioEmail = {
    dataField: "email",
    caption: "E-mail"
};

export const usuarioColunas = [
    colunaUsuarioNome,
    colunaUsuarioEmail
];

export let listarEmails = (parametros) =>  {
    return new DataSource({
        paging: {
            pageSize: 10
        },
        pager: {
            showPageSizeSelector: true,
            allowedPageSizes: [20, 50, 100],
            showInfo: true
        },
        key: "usuario_id",
        load: (loadOptions) => {
            let dxParams = window.loadOptionsToActionParams(loadOptions);
            dxParams.usuario = loadOptions.searchValue;
            let promise = new Promise(function(resolve, reject) {
                WS.get({
                route: "usuario/listar_usuarios_email/", 
                    data: {
                        dx_grid: true,
                        ...dxParams,
                        ...parametros
                    },
                    func_response: function(res) {
                        let paramsGrid = window.createLoadExtra(res);
                        resolve({...paramsGrid, data: res })
                    },
                    func_fail: function(res) {
                        if (res.validation) {
                            reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                        }
                    }
                });
            });
            return promise;
        }
    })
};

export let listarUsuarios = (parametros) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'id_usuario_grupo',
    load: (loadOptions) => {
        let dxParams = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "usuario/listar_usuarios_grupos/", 
                data: {
                    ...dxParams,
                    ...parametros
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;

        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "usuario/listar_usuarios_grupos/", 
                data: {
                    dx_grid: true,
                    id_usuario_grupo: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});