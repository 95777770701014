import React, { useContext } from 'react';
import WS from '../../api/ws';
import App from '../../api/app';
import View from '../../api/view';

import { reclassificar } from '../../datasource/Chamado';

import CardReclassificacao from './CardReclassificacao';
import CardTopo from './CardTopo';
import CardMensagemChamado from './CardMensagemChamado';
import CardReferencias from './CardReferencias';

import { VerticalSpacer, HorizontalSpacer } from '../../components/Spacer';
// eslint-disable-next-line
import ScrollView from 'devextreme-react/scroll-view';

import './Detalhes.scss';
import CardAvaliacaoFormulario from './CardAvaliacaoFormulario';
import CardFormularioAbertura from './CardFormularioAbertura';

// Contextos
import { MensagemProvider } from '../../contexts/MensagemContext';

export default class Detalhes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            novo: false,
            chamadoId: typeof props.chamadoId != 'undefined' ? props.chamadoId : 0,
            cartaoId: props.cartaoId ?? null,
            projetoAtividadeId: props.projetoAtividadeId ?? null,
            projetoId: props.projetoId ?? null,
            oportunidadeId: props.oportunidadeId ?? null,
            solicitacaoAtividadeId: props.solicitacaoAtividadeId ?? null,
            solicitacaoId: props.solicitacaoId ?? null,
            pdcaId: props.pdcaId ?? null,
            pdcaAtividadeId: props.pdcaAtividadeId ?? null,
            assunto: props.assunto ?? null,
            emails: props.emails ?? null,
            entidadeId: props.entidadeId ?? null,
            entidadeTipo: props.entidadeTipo ?? null,
            situacao: null,
            entidadeNome: null,
            detalhes: null,
            mensagens: null,
            referencias: null,
            botaoPressionado: 'mensagens',
            botaoPressionadoReclassificacao: false,
            botaoPressionadoAvaliar: props.botaoPressionadoAvaliar ?? false,
            carregandoMensagem: false,
            exibirBotaoDownloadAnexos: false,
            exibirMaisDetalhes: props.exibirMaisDetalhes ?? (() => {
                let parametroChamadoDetalhes = App.get_parametro_usuario("chamado_detalhes");
                if (parametroChamadoDetalhes) {
                    if (typeof parametroChamadoDetalhes.exibirMaisDetalhes !== 'undefined' && parametroChamadoDetalhes.exibirMaisDetalhes !== '') {
                        return parametroChamadoDetalhes.exibirMaisDetalhes;
                    }
                }
                return false
            })(),
            dataEnvioExtenso: props.dataEnvioExtenso ?? (() => {
                let parametroChamadoDetalhes = App.get_parametro_usuario("chamado_detalhes");
                if (parametroChamadoDetalhes) {
                    if (typeof parametroChamadoDetalhes.dataEnvioExtenso !== 'undefined' && parametroChamadoDetalhes.dataEnvioExtenso !== '') {
                        return parametroChamadoDetalhes.dataEnvioExtenso;
                    }
                }
                return false
            })(),
        };

        this.getInstanceMaisDetalhes = this.getInstanceMaisDetalhes.bind(this);
        this.getInstanceMensagens = this.getInstanceMensagens.bind(this);

        this.obterDetalhes = this.obterDetalhes.bind(this);
        this.aoSalvarMensagem = this.aoSalvarMensagem.bind(this);
        this.aoAlterarBotaoPressionadoCardNovaMensagem = this.aoAlterarBotaoPressionadoCardNovaMensagem.bind(this);
        this.aoAlterarReclassificacoesMemoria = this.aoAlterarReclassificacoesMemoria.bind(this);
        this.btnToogleExibirMaisDetalhesClick = this.btnToogleExibirMaisDetalhesClick.bind(this);
        this.aoAlterarTipoExibicaoDataEnvio = this.aoAlterarTipoExibicaoDataEnvio.bind(this);
    }

    getInstanceMaisDetalhes(ref) {
        if (ref) {
            let element = ref.instance.element();
            window.set_height(element, element.closest('[div-view]').find('.cardTopo').height() + 50);
        }
    }

    getInstanceMensagens(ref) {
        if (ref) {
            let element = ref.instance.element();
            window.set_height(element, element.closest('[div-view]').find('.cardTopo').height() + 50);
        }
    }

    componentDidMount() {
        this.state.chamadoId === 0 ? this.novo() : this.obterDetalhes();
    }

    componentWillUnmount() {
        if (this.props.onClose) this.props.onClose();
    }

    novo() {
        let dadosRascunho = {
            rascunho: true,
            usuario_solicitante_id: App.sessao.dados.usuario_id,
            situacao: window.CHAMADO_SITUACAO.AGUARDANDO_ATENDIMENTO
        };

        if (this.state.cartaoId) dadosRascunho = { ...dadosRascunho, tarefa_quadro_lista_cartao_id: this.state.cartaoId };
        if (this.state.projetoAtividadeId) dadosRascunho = { ...dadosRascunho, projeto_atividade_id: this.state.projetoAtividadeId };
        if (this.state.projetoId) dadosRascunho = { ...dadosRascunho, projeto_id: this.state.projetoId };
        if (this.state.assunto) dadosRascunho = { ...dadosRascunho, assunto: this.state.assunto };
        if (this.state.oportunidadeId) dadosRascunho = { ...dadosRascunho, oportunidade_id: this.state.oportunidadeId };
        if (this.state.solicitacaoAtividadeId) dadosRascunho = { ...dadosRascunho, solicitacao_atividade_id: this.state.solicitacaoAtividadeId };
        if (this.state.solicitacaoId) dadosRascunho = { ...dadosRascunho, solicitacao_id: this.state.solicitacaoId };
        if (this.state.pdcaId) dadosRascunho = { ...dadosRascunho, pdca_id: this.state.pdcaId };
        if (this.state.pdcaAtividadeId) dadosRascunho = { ...dadosRascunho, pdca_atividade_id: this.state.pdcaAtividadeId };
        
        if (this.state.emails && App.sessao.dados.parametro_chamado.sugerir_email === 'S') {
            dadosRascunho = { ...dadosRascunho, emails_adicionais: this.state.emails };
        }
        
        if (
            App.sessao.dados.parametro_chamado.sugerir_entidade_usuario_logado === 'S'
            && !this.state.oportunidadeId
            && !this.state.solicitacaoAtividadeId
            && !this.state.solicitacaoId
        ) {
            switch (+App.sessao.dados.tipo) {
                case window.USUARIO_TIPO.CLIENTE:
                    dadosRascunho = { ...dadosRascunho, entidade_tipo: window.TIPO_ENTIDADE.CLIENTE, entidade_id: App.sessao.dados.cliente_id }
                    break;
                case window.USUARIO_TIPO.ANALISTA:
                    dadosRascunho = { ...dadosRascunho, entidade_tipo: window.TIPO_ENTIDADE.EMPRESA, entidade_id: App.temp.empresa }
                    break;
                case window.USUARIO_TIPO.FORNECEDOR:
                    dadosRascunho = { ...dadosRascunho, entidade_tipo: window.TIPO_ENTIDADE.FORNECEDOR, entidade_id: App.sessao.dados.fornecedor.fornecedor_id }
                    break;
                case window.USUARIO_TIPO.PROSPECT:
                    dadosRascunho = { ...dadosRascunho, entidade_tipo: window.TIPO_ENTIDADE.ORGANIZACAO, entidade_id: App.sessao.dados.organizacao.organizacao_id }
                    break;
                default:
                    break;
            }
           
        }

        if (this.state.entidadeId) dadosRascunho = { ...dadosRascunho, entidade_id: this.state.entidadeId }
        if (this.state.entidadeTipo) dadosRascunho = { ...dadosRascunho, entidade_tipo: this.state.entidadeTipo }

        reclassificar(
            dadosRascunho,
            this.obterDetalhes
        );

        this.setState({
            novo: true,
            exibirMaisDetalhes: true
        })
    }

    verificaDocumentosEmMensagens(mensagens) {
        let exibirBotaoDownloadAnexos = false;
        if (mensagens) {
            mensagens.every(mensagem => {
                if (mensagem.documentos.length > 0) {
                    exibirBotaoDownloadAnexos = true;
                    return false;
                }
                return true;
            });
        }
        return exibirBotaoDownloadAnexos;
    }

    setStateResponse(response, novoState) {
        if (response && response.chamado) {            
            App.titulo_aba(this.props.html_id, 'Chamado #' + response.chamado.id);
            this.setState({
                detalhes: response.chamado,
                chamadoId: response.chamado.id,
                entidadeNome: response.chamado.entidade_nome,
                assunto: response.chamado.assunto,
                situacao: response.chamado.situacao,
                mensagens: response.mensagens,
                carregandoMensagem: false,
                exibirBotaoDownloadAnexos: this.verificaDocumentosEmMensagens(response.mensagens),
                exibirMaisDetalhes: this.state.exibirMaisDetalhes || response.chamado.rascunho,
                ...novoState
            });
        }
    }

    obterDetalhes(response, novoState) {
        if (typeof response !== 'undefined' && response && response.chamado) {
            this.setStateResponse(response, novoState);
        }
        else if (!this.state.chamadoId) {
            return;
        }
        else {
            this.setState({ carregandoMensagem: true });
            
            WS.get({
                route: "chamado/listar_detalhes_chamado/",
                data: { chamado_id: this.state.chamadoId },
                func_response: (response) => {
                    this.setStateResponse(response, novoState);
                },
                func_fail: () => {
                    this.setState({
                        detalhes: null,
                        carregandoMensagem: false
                    });
                    View.unload(this.props.html_id);
                }
            });
        }
    }

    aoSalvarMensagem(response) {
        let novoState = {
            reclassificacoesMemoria: [],
            botaoPressionadoReclassificacao: false,
            botaoPressionadoAvaliar: false,
        };
        this.obterDetalhes(response, novoState);
        if (this.state.novo) {
            this.setState({ novo: false, ...novoState });
        }
    }

    aoAlterarBotaoPressionadoCardNovaMensagem(botaoPressionado) {
        this.setState({
            botaoPressionadoReclassificacao: botaoPressionado === 'reclassificar'
        });
    }

    renderReferencias() {
        return <CardReferencias detalhes={this.state.detalhes}></CardReferencias>;
    }

    renderFormularioAvaliacao() {
        return  <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-body">
                                <CardAvaliacaoFormulario detalhes={this.state.detalhes} readOnly={true}/>
                            </div>
                        </div>
                    </div>
                </div>;
    }

    renderFormularioAbertura() {
        return  <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-body">
                                <CardFormularioAbertura chamadoId={this.state.detalhes.id} chamadoMotivoId={this.state.detalhes.chamado_motivo_id} readOnly={+App.sessao.dados.tipo !== window.USUARIO_TIPO.ANALISTA}/>
                            </div>
                        </div>
                    </div>
                </div>;
    }

    renderMensagensLoading() {
        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="">
                        <div className="row">
                            <div className="col-xs-12 text-left">
                                <div className="animationLoading">
                                    <div className="animationLoadingTopo">
                                        <div className="animationLoadingFoto"></div>
                                        <HorizontalSpacer/>
                                        <div>
                                            <div className="animationLoadingLineA"></div>
                                            <VerticalSpacer/>
                                            <div className="animationLoadingLineB"></div>
                                            <VerticalSpacer/>
                                            <div className="animationLoadingLineC"></div>
                                            <VerticalSpacer/>
                                            <div className="animationLoadingLineD"></div>
                                            <VerticalSpacer/>
                                            <div className="animationLoadingLineE"></div>
                                        </div>
                                    </div>
                                </div>
                                <VerticalSpacer/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMensagens() {
        return (
            typeof this.state.mensagens != 'undefined' && Array.isArray(this.state.mensagens) ? this.state.mensagens.map((mensagem) => 
                <CardMensagemChamado
                    editavel={false}
                    key={mensagem.id.toString()}
                    mensagem={mensagem}
                    aoSalvar={this.aoSalvarMensagem}
                    chamadoId={this.state.chamadoId}
                    chamadoMensagemId={mensagem.id}
                    aoAlterarTipoExibicaoDataEnvio={this.aoAlterarTipoExibicaoDataEnvio}
                    dataEnvioExtenso={this.state.dataEnvioExtenso}
                    notificarEnvolvidos={this.state.detalhes.valor_padrao_notificar_envolvolvidos == "S"}
                />
            ) : null
        );
    }

    btnToogleExibirMaisDetalhesClick() {
        this.setState({exibirMaisDetalhes: !this.state.exibirMaisDetalhes});
        App.set_parametro_usuario("chamado_detalhes", {
            exibirMaisDetalhes: this.state.exibirMaisDetalhes
        });
        return this.state.exibirMaisDetalhes;
    }

    aoAlterarTipoExibicaoDataEnvio(extenso) {
        this.setState({
            dataEnvioExtenso: extenso
        });

        App.set_parametro_usuario("chamado_detalhes", {
            dataEnvioExtenso: extenso
        });
    }

    aoAlterarReclassificacoesMemoria(reclassificacoesMemoria) {
        this.setState({ reclassificacoesMemoria: reclassificacoesMemoria });
    }

    renderReclassificacao() {
        if (!this.state.detalhes) return null;

        return (
            <CardReclassificacao
                chamadoId={this.state.chamadoId}
                detalhes={this.state.detalhes}
                aoSolicitarRefresh={this.obterDetalhes}
                botaoPressionadoReclassificacao={this.state.botaoPressionadoReclassificacao}
                reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                aoAlterarReclassificacoesMemoria={this.aoAlterarReclassificacoesMemoria}
            />);
    }

    render() {
        if (!this.state.detalhes) return (
            <div>Carregando, aguarde...</div>
        );

        const exibirMaisDetalhes = (this.state.botaoPressionadoReclassificacao || this.state.exibirMaisDetalhes);

        const classNameMaisDetalhes = exibirMaisDetalhes ? "col-md-3" : "hidden";
        const classNameMensagens = exibirMaisDetalhes ? "col-md-9" : "col-md-12";
        return(
            <MensagemProvider>
                <div>
                    <div className="row cardTopo">
                        <div className="col-md-12">
                            <CardTopo
                                viewHtmlId={this.props.html_id}
                                chamadoId={this.state.chamadoId}
                                detalhes={this.state.detalhes}
                                mensagensCount={typeof this.state.mensagens != 'undefined' && Array.isArray(this.state.mensagens) ? this.state.mensagens.length : 0}
                                botaoPressionado={this.state.botaoPressionado}
                                exibirMaisDetalhes={exibirMaisDetalhes}
                                bloquearExibirMaisDetalhes={this.state.botaoPressionadoReclassificacao}
                                btnToogleExibirMaisDetalhesClick={this.btnToogleExibirMaisDetalhesClick}
                                btnMensagensClick={() => this.setState({botaoPressionado: 'mensagens'})}
                                btnFormularioAvaliacaoClick={() => this.setState({botaoPressionado: 'avaliacao'})}
                                btnFormularioAberturaClick={() => this.setState({botaoPressionado: 'formularioAbertura'})}
                                btnReferenciasClick={() => this.setState({botaoPressionado: 'referencias'})}
                                btnRecarregarClick={this.obterDetalhes}
                                aoSolicitarRefresh={this.obterDetalhes}
                        />
                        </div>
                    </div>
                    <div className="row">
                        {exibirMaisDetalhes ?
                            <div className={classNameMaisDetalhes}>        
                                {/*<ScrollView ref={this.getInstanceMaisDetalhes}>*/}
                                    <div className="text-content">
                                        {this.renderReclassificacao()}
                                    </div>
                                {/*</ScrollView>*/}
                            </div>
                        : null}
                        <div className={classNameMensagens}>
                            {/* <ScrollView ref={this.getInstanceMensagens}> */}
                                <div className="text-content">
                                    <React.Fragment>
                                        {this.state.botaoPressionado === 'mensagens' ?
                                        <React.Fragment>
                                            <CardMensagemChamado
                                                viewHtmlId={this.props.html_id}
                                                detalhes={this.state.detalhes}
                                                nova={true}
                                                editavel={true}
                                                aoSalvar={this.aoSalvarMensagem}
                                                chamadoId={this.state.chamadoId}
                                                chamadoSituacao={this.state.situacao}
                                                exibirBotaoDownloadAnexos={this.state.exibirBotaoDownloadAnexos}
                                                aoAlterarBotaoPressionado={this.aoAlterarBotaoPressionadoCardNovaMensagem}
                                                reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                                                aoSolicitarRefresh={this.obterDetalhes}
                                                botaoPressionado={this.state.botaoPressionadoAvaliar ? 'avaliar' : null}
                                                notificarEnvolvidos={this.state.detalhes.valor_padrao_notificar_envolvolvidos == "S"}
                                            />
                                            {this.state.carregandoMensagem ?
                                                this.renderMensagensLoading()
                                            : null}
                                            {this.renderMensagens()}
                                        </React.Fragment>
                                        : null}

                                        {this.state.botaoPressionado === 'referencias' ?
                                        this.renderReferencias()
                                        : null}

                                        {this.state.botaoPressionado === 'avaliacao' ?
                                        this.renderFormularioAvaliacao()
                                        : null}

                                        {this.state.botaoPressionado === 'formularioAbertura' ?
                                        this.renderFormularioAbertura()
                                        : null}
                                    </React.Fragment>
                                </div>
                            {/* </ScrollView> */}
                        </div>
                    </div>
                </div>
            </MensagemProvider>
        )
    }
}

Detalhes.defaultProps = {
    abaTitulo: "Chamado",
    cartaoId: null,
};